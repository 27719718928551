<template>
  <div class="section-gallery-slider-container">
    <div class="section-gallery-slider-track">
      <div class="lineUp" v-if="screen.width < 600"></div>
      <picture>
        <source :srcset="banner.desktop" media="(min-width: 600px)" />
        <img :src="banner.mobile" alt="modelos" />
      </picture>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GalleryBannerSlider",
  props: ["banner"],
  data: function () {
    return {
      images: [],
    };
  },
  computed: {
    screen() {
      return this.$store.state.screen;
    },
  },
};
</script>

<style lang="scss">
.section-gallery-slider {
  &-container {
    margin: auto;
    position: relative;
    padding: 80px 10px 0 10px;
  }
  &-container {
    overflow: hidden;
  }
  &-track {
    img {
      max-width: 1140px;
      width: 100%;
    }
    .line {
      margin: 0 auto;
      max-width: 1140px;
      width: 100%;
      height: 8px;
      background-color: $primary_color;
      margin-top: -5px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    .lineUp {
      width: 100%;
      height: 6px;
      background-color: $primary_color;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }
}
</style>
